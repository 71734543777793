import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ADMIN_GROUPS} from "../../../../utils/constants_urls";
import {fetchApiGet} from "../../../../utils/axios";
import {groupsArrSave} from "../../../../store/slicers/course-slice";
import useToken from "../../../hooks/useToken";

import {CourseCreateWrapper, DescriptionWrapperCreateCourse, GroupWrapper} from "../StyledCourse";


const CourseCreateBody = (props) => {
	const {
		selectGroupHandler,
		selectedGroup,
		show,
		setShow,
		setDescriptionInput,
		descriptionInput,
		nameInput,
		setNameInput,
		selectedAgeGroupFrom,
		setSelectedAgeGroupFrom,
		selectedAgeGroupTo,
		setSelectedAgeGroupTo
	} = props;

	const {groupsArr} = useSelector(state => state.course);
	const {courseItem} = useSelector(state => state.course);
	const {token} = useToken();
	const dispatch = useDispatch();

	const [valueGroup, setValueGroup] = useState('');
	const [groups, setGroups] = useState([]);
	useEffect(() => {
		if (courseItem && courseItem.course_id) {
			const group = groupsArr.filter(group => group.group_id === courseItem.group_id && selectGroupHandler(group));
		}
	}, []);

	useEffect(()=>{
		if(courseItem && courseItem.group_id === selectedGroup.group_id){
			setValueGroup(selectedGroup.group_name)
		}
	},[selectedGroup])

	useEffect(()=>{
		if(groupsArr){
			setGroups(groupsArr);
		}
	}, [groupsArr])
	const getGroup = async () => {
		const url = ADMIN_GROUPS;
		const response = await fetchApiGet({url, token});

		dispatch(groupsArrSave(response));
	};

	useEffect(() => {
		if (!groupsArr.length) {
			getGroup();
		}
	}, [groupsArr]);


	const onChangeDescription = (el) => {
		setDescriptionInput(el.target.value);
	};
	const onChangeSelectedAgeGroupFrom = (el) => {
		setSelectedAgeGroupFrom(el.target.value.replace(/\D/g, ''));
	};
	const onChangeSelectedAgeGroupTo = (el) => {
		setSelectedAgeGroupTo(el.target.value.replace(/\D/g, ''));
	};
	const onChangeName = (el) => {
		setNameInput(el.target.value);
	};

	const onChangeGroup = (el) => {

		const searchGroup = el.target.value.toLowerCase();
		setValueGroup(searchGroup);
		const x = groups.filter(i => {
			if (`${i.group_name}`.toLowerCase().includes(searchGroup)) {
				return i;
			}
		});
		setGroups(x);
	}

	useEffect(()=>{
		if (valueGroup === ''){
			setGroups(groupsArr);
		}
	},[valueGroup]);

	const selectGroupClick = (group) => {
		// console.log("selectGroupClick", group)
		selectGroupHandler(group);
		setValueGroup(group.group_name)
	}



	return (
		<>
			<CourseCreateWrapper>
				<label>
					<div>* <span>Course name</span> required</div>
					<input type="text"
								 value={nameInput}
								 placeholder="Enter text"
								 onChange={(el) => onChangeName(el)}/>
				</label>
				<div style={{position: "relative"}}>
					<label onClick={() => setShow(!show)}>
						<div>* <span>Course group</span> required</div>
							<input type="text" placeholder="Enter text"
									 value={valueGroup}
									 // value={courseItem && courseItem.course_id ? valueGroup ? valueGroup : selectedGroup.group_name : valueGroup}
									 onChange={(el)=> onChangeGroup(el)}/>
					</label>
					{show &&
						<GroupWrapper>
							{
								groups.length ? (
									groups.map(group => (
										<li key={group.group_id} onClick={() => selectGroupClick(group)}>{group.group_name}</li>
									))
								) : null
							}
						</GroupWrapper>
					}
				</div>

				<label>
					<div>* <span>Age from</span> required</div>
					<input type="text"
								 placeholder="Enter text"
								 value={selectedAgeGroupFrom}
								 maxLength='1'
								 onChange={(el) => onChangeSelectedAgeGroupFrom(el)}/>
				</label>
				<label>
					<div>* <span>Age under</span> required</div>
					<input type="text"
								 placeholder="Enter text"
								 value={selectedAgeGroupTo}
								 maxLength='1'
								 onChange={(el) => onChangeSelectedAgeGroupTo(el)}/>
				</label>
			</CourseCreateWrapper>
			<DescriptionWrapperCreateCourse>
				<label>
					<div>* <span>Course description </span> required</div>
					<textarea placeholder="Enter text"
										value={descriptionInput}
										onChange={(el) => onChangeDescription(el)}></textarea>
				</label>
			</DescriptionWrapperCreateCourse>
		</>
	);
};

export default CourseCreateBody;